import { AssetResponse, AssetSimpleResponse, EAssetType } from 'api/core';
import { ControlledModal } from 'components/ControlledModal';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { VideoPreview } from 'components/Video/VideoPreview';
import { useState } from 'react';
import { PlayIcon } from 'lucide-react';
import { AudioPreview } from 'components/Audio/AudioPreview';

interface AssetPreviewModalProps {
  asset: AssetResponse | AssetSimpleResponse;
  disablePreview?: boolean;
  information?: string;
  className?: string;
  trimStart?: number;
  trimEnd?: number;
}

export const AssetPreviewModal = ({
  asset,
  disablePreview,
  information,
  className,
  trimStart,
  trimEnd,
}: AssetPreviewModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
  };

  return (
    <>
      {disablePreview ? (
        <ActionCell
          icon={
            <AnimatedIcon icon="open-icon" className="h-8 md:h-6 w-8 md:w-6" />
          }
          onClick={() => onModalStateChange(true)}
        />
      ) : (
        <div
          onClick={() => onModalStateChange(true)}
          className="cursor-pointer"
        >
          {asset.type === EAssetType.Image ? (
            <img
              src={asset.url}
              alt={asset.originalFileName}
              className={className}
            />
          ) : asset.type === EAssetType.Video ? (
            <div className="relative flex">
              <video preload="metadata" crossOrigin="anonymous">
                <source src={asset.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 rounded-full p-3 hover:bg-opacity-75">
                <PlayIcon fill="white" className="w-5 h-5 text-white" />
              </div>
            </div>
          ) : asset.type === EAssetType.Audio ? (
            <AudioPreview asset={asset} volume={1} className="w-full" />
          ) : (
            <div>
              <p className="text-gray-500 text-sm">
                Forhåndsvisning ikke tilgængelig
              </p>
            </div>
          )}
        </div>
      )}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title={asset.originalFileName}
        closeOnOutsideClick
        hideActionBar
      >
        {information ? (
          <div
            role="alert"
            className="alert alert-info rounded-none text-white mt-2"
          >
            {information}
          </div>
        ) : null}

        <div className="py-3">
          {asset.type === EAssetType.Image ? (
            <img
              src={asset.url}
              alt={asset.originalFileName}
              className="w-full"
            />
          ) : asset.type === EAssetType.Video ? (
            <VideoPreview
              asset={asset}
              className="w-full"
              trimStart={trimStart}
              trimEnd={trimEnd}
            />
          ) : asset.type === EAssetType.Audio ? (
            <AudioPreview asset={asset} volume={1} className="w-full" />
          ) : (
            <div>
              <p>
                Forhåndsvisning ikke tilgængelig for denne filtype. Du kan
                downloade filen for at se indholdet.
              </p>
              <button
                className="btn btn-primary mt-4"
                onClick={() => window.open(asset.url)}
              >
                Download
              </button>
            </div>
          )}
        </div>
      </ControlledModal>
    </>
  );
};
