import {
  CaseResponse,
  EMediaSequenceType,
  EPermission,
  MediaSequenceCreateRequest,
  MediaSequenceResponse,
  UserSimpleResponse,
} from 'api/core';
import {
  useCloneMediaSequence,
  useCreateMediaSequence,
  useSearchMediaSequences,
  useSearchMediaSequenceTemplates,
} from 'api/useMediaSequencesApi';
import { useSearchUsers } from 'api/useUsersApi';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import {
  AddressSuggestion,
  AutocompleteAddress,
} from 'components/Form/AutocompleteAddress';
import { LabelWithHelperText } from 'components/Form/LabelWithHelperText';
import { AnimatedIconKey } from 'components/Icon/AnimatedIcon';
import { InlineLoading } from 'components/Loading';
import { EntitySelectSingleAsync } from 'components/Select/EntitySelectSingleAsync';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface MediaSequenceCreateFormProps {
  existingMediaSequence?: MediaSequenceResponse;
  existingCase?: CaseResponse;
  onSuccess?: () => void;
  onCancel?: () => void;
  inDialog?: boolean;
}

export const MediaSequenceCreateForm = ({
  existingMediaSequence,
  existingCase,
  onSuccess,
  onCancel,
  inDialog,
}: MediaSequenceCreateFormProps) => {
  const { data: templates } = useSearchMediaSequenceTemplates();

  const [isUsingTemplate, setIsUsingTemplate] = useState<boolean>(false);

  const [loadingMessage, setLoadingMessage] = useState<{
    message: string;
    icon: AnimatedIconKey;
  } | null>(null);

  const [selectedMediaSequence, setSelectedMediaSequence] =
    useState<MediaSequenceResponse | null>(existingMediaSequence ?? null);

  const [selectedSuggestion, setSelectedSuggestion] =
    useState<AddressSuggestion>();

  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCreateMediaSequence();
  const { mutateAsync: cloneAsync, isPending: IsPendingClone } =
    useCloneMediaSequence();

  // Automatically select the first template if there are any, and no existing case or media sequence is provided
  useEffect(() => {
    if (
      templates &&
      templates.length > 0 &&
      !existingCase &&
      !existingMediaSequence
    ) {
      setIsUsingTemplate(true);
      setSelectedMediaSequence(templates[0]);
    }
  }, [templates, existingCase, existingMediaSequence]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<MediaSequenceCreateRequest>({
    defaultValues: {
      name: existingMediaSequence?.name,
      caseId: existingCase?.id ?? null,
      metaMappingUserId: null,
    },
  });

  const onSubmit = handleSubmit(async (result) => {
    if (selectedSuggestion && selectedSuggestion.forslagstekst) {
      result.name = selectedSuggestion.forslagstekst;
      result.dawaAddressId = selectedSuggestion.data.id;
    }

    setLoadingMessage({
      message: 'Opretter video...',
      icon: 'loading-icon',
    });

    if (selectedMediaSequence) {
      const cloneResult = await cloneAsync({
        id: selectedMediaSequence.id,
        mediaSequenceCloneRequest: result,
      });
      navigate(`/media-sequences/${cloneResult.id}`);
    } else {
      const mediaSequence = await mutateAsync({
        mediaSequenceCreateRequest: result,
      });
      navigate(`/media-sequences/${mediaSequence.id}`);
    }

    setLoadingMessage(null);
    onSuccess?.();
  });

  const onUserSelected = (userResponse: UserSimpleResponse | null) => {
    setValue('metaMappingUserId', userResponse?.id, { shouldValidate: true });
  };

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      {templates && templates.length > 0 ? (
        <div className="form-control">
          <LabelWithHelperText
            label="Skabelon"
            helperText="Du har mulighed for at vælge en skabelon som udgangspunkt for din nye video. Dette vil kopiere alle indstillinger fra den valgte skabelon."
            inDialog={inDialog}
          />
          <ul className="menu menu-md pl-0 menu-horizontal m-0 pt-0 gap-1 before:content-none">
            {templates.map((template) => (
              <li key={template.id}>
                <a
                  className={twMerge(
                    'border border-gray-300',
                    isUsingTemplate &&
                      selectedMediaSequence?.id === template.id &&
                      'active'
                  )}
                  onClick={() => {
                    setSelectedMediaSequence(template);
                    setIsUsingTemplate(true);
                  }}
                >
                  {template.name}
                </a>
              </li>
            ))}
            <li>
              <a
                className={twMerge(
                  'border border-gray-300',
                  !isUsingTemplate && 'active',
                  isUsingTemplate && 'opacity-40'
                )}
                onClick={() => {
                  setIsUsingTemplate(false);
                }}
              >
                Ingen skabelon
              </a>
            </li>
          </ul>
        </div>
      ) : null}

      <AutocompleteAddress
        label={
          <p>
            Adresse <span className="text-red-500">*</span>
          </p>
        }
        registration={() => register('name', { required: true })}
        formWatch={watch('name') || ''}
        formSetValue={(value: string) =>
          setValue('name', value, { shouldValidate: true })
        }
        inputClassName="input input-bordered"
        onSuggestionSelectedFn={setSelectedSuggestion}
      />

      <PermissionProtectedComponent
        permissions={[EPermission.UserSimpleSearch]}
      >
        <div className="form-control">
          <LabelWithHelperText
            label={
              <p>
                Tilknyt mægler <span className="text-red-500">*</span>
              </p>
            }
            helperText="Der bliver automatisk indhentet stamdata for den valgte mægler."
            inDialog={inDialog}
          />
          <EntitySelectSingleAsync<UserSimpleResponse, { searchTerm?: string }>
            useSearchFunction={useSearchUsers}
            searchParams={{}}
            required={true}
            renderSuggestion={(userSuggestion) => (
              <>
                {userSuggestion.name}{' '}
                {userSuggestion.title ? `(${userSuggestion.title})` : undefined}
              </>
            )}
            onSuggestionSelected={onUserSelected}
            inDialog={inDialog}
          />

          <input
            {...register('metaMappingUserId', { required: true })}
            type="text"
            className="input input-bordered hidden"
          />
        </div>
      </PermissionProtectedComponent>

      {!isUsingTemplate &&
      existingMediaSequence &&
      existingMediaSequence.type !== EMediaSequenceType.Template &&
      existingMediaSequence.type !== EMediaSequenceType.StrictTemplate ? (
        <div className="form-control">
          <LabelWithHelperText
            label="Brug eksisterende video som skabelon"
            helperText="Du har mulighed for at vælge en eksisterende video som udgangspunkt for din nye video. Dette vil kopiere alle indstillinger fra den valgte video."
            inDialog={inDialog}
          />
          <EntitySelectSingleAsync<
            MediaSequenceResponse,
            { searchTerm?: string }
          >
            useSearchFunction={useSearchMediaSequences}
            searchParams={{}}
            renderSuggestion={(mediaSequenceSuggestion) => (
              <>
                {mediaSequenceSuggestion.type === EMediaSequenceType.Template ||
                mediaSequenceSuggestion.type ===
                  EMediaSequenceType.StrictTemplate ? (
                  <span className="font-bold mr-1">[Skabelon]</span>
                ) : null}
                {mediaSequenceSuggestion.name}
              </>
            )}
            onSuggestionSelected={setSelectedMediaSequence}
            initialValue={existingMediaSequence}
            inDialog={inDialog}
          />
        </div>
      ) : null}

      {
        // TODO: Remove when loading indicator supports being on top of modals
      }
      {loadingMessage ? (
        <InlineLoading
          text={loadingMessage.message}
          icon={loadingMessage.icon}
        />
      ) : null}

      <div className="flex justify-center space-x-4 pt-4">
        <button
          className="btn btn-primary"
          disabled={isPending || IsPendingClone || !isValid}
        >
          Opret
        </button>
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
