import {
  AssetSimpleResponse,
  EAssetType,
  MediaSequenceBranchMutationCreateRequest,
  MediaSequenceBranchMutationResponse,
  MediaSequenceBranchMutationUpdateRequest,
} from 'api/core';
import { AudioPreview } from 'components/Audio/AudioPreview';
import { AssetUpload } from 'components/Form/AssetUpload';
import { AssetSelectSingleAsync } from 'components/Select/AssetSelectSingleAsync';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface AddBackgroundMusicProps {
  targetBranchMutation?: MediaSequenceBranchMutationResponse;
  projectId?: string;
  inDialog?: boolean;
}

export const AddBackgroundMusic = ({
  targetBranchMutation,
  projectId,
  inDialog,
}: AddBackgroundMusicProps) => {
  const {
    register,
    setValue,
    watch,
    formState: { disabled },
  } = useFormContext<
    | MediaSequenceBranchMutationCreateRequest
    | MediaSequenceBranchMutationUpdateRequest
  >();

  const backgroundMusicfileInputRef = useRef<HTMLInputElement>(null);
  const [
    initialSelectedBackgroundMusicAsset,
    setInitialSelectedBackgroundMusicAsset,
  ] = useState<AssetSimpleResponse | undefined>(
    targetBranchMutation?.addBackgroundMusic?.asset
  );

  const onBackgroundAudioSelected = (asset: AssetSimpleResponse | null) => {
    if (!asset) return;
    setValue('addBackgroundMusic.assetId', asset.id);
    setInitialSelectedBackgroundMusicAsset(asset);
  };

  const volume = watch('addBackgroundMusic.backgroundAudioVolume');

  return (
    <>
      <AssetUpload
        fileInputRef={backgroundMusicfileInputRef}
        projectId={projectId}
        onAssetUploaded={onBackgroundAudioSelected}
        accept="audio/*"
      />
      <div className="form-control">
        <label className="label">
          <span className="label-text">Lydfil</span>
        </label>
        <AssetSelectSingleAsync
          // Allow the user to seearch in all assets on organization (Not just project assets)
          // projectId={projectId}
          types={[EAssetType.Audio]}
          onSelected={onBackgroundAudioSelected}
          initialValue={initialSelectedBackgroundMusicAsset}
          showCreateOption
          onCreateOptionSelected={() =>
            backgroundMusicfileInputRef.current?.click()
          }
          inDialog={inDialog}
          disabled={disabled}
        />
        <input
          {...register('addBackgroundMusic.assetId', { required: true })}
          type="text"
          className="hidden"
        />
        {initialSelectedBackgroundMusicAsset ? (
          <AudioPreview
            className="mt-4"
            asset={initialSelectedBackgroundMusicAsset}
            volume={volume}
          />
        ) : null}
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Lydniveau</span>
        </label>
        <input
          {...register('addBackgroundMusic.backgroundAudioVolume', {
            required: true,
          })}
          defaultValue={0.12}
          type="range"
          min={0.05}
          max={0.4}
          step={0.07}
          className="range range-primary"
        />
        <div className="flex w-full justify-between px-2 text-xs">
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
        </div>
      </div>
    </>
  );
};
