/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaseResponse } from './CaseResponse';
import {
    CaseResponseFromJSON,
    CaseResponseFromJSONTyped,
    CaseResponseToJSON,
} from './CaseResponse';
import type { EConcatenationMode } from './EConcatenationMode';
import {
    EConcatenationModeFromJSON,
    EConcatenationModeFromJSONTyped,
    EConcatenationModeToJSON,
} from './EConcatenationMode';
import type { EMediaSequenceState } from './EMediaSequenceState';
import {
    EMediaSequenceStateFromJSON,
    EMediaSequenceStateFromJSONTyped,
    EMediaSequenceStateToJSON,
} from './EMediaSequenceState';
import type { EMediaSequenceType } from './EMediaSequenceType';
import {
    EMediaSequenceTypeFromJSON,
    EMediaSequenceTypeFromJSONTyped,
    EMediaSequenceTypeToJSON,
} from './EMediaSequenceType';
import type { ETranscriptionState } from './ETranscriptionState';
import {
    ETranscriptionStateFromJSON,
    ETranscriptionStateFromJSONTyped,
    ETranscriptionStateToJSON,
} from './ETranscriptionState';
import type { MediaSequenceBranchMutationResponse } from './MediaSequenceBranchMutationResponse';
import {
    MediaSequenceBranchMutationResponseFromJSON,
    MediaSequenceBranchMutationResponseFromJSONTyped,
    MediaSequenceBranchMutationResponseToJSON,
} from './MediaSequenceBranchMutationResponse';
import type { MediaSequenceBranchResponse } from './MediaSequenceBranchResponse';
import {
    MediaSequenceBranchResponseFromJSON,
    MediaSequenceBranchResponseFromJSONTyped,
    MediaSequenceBranchResponseToJSON,
} from './MediaSequenceBranchResponse';
import type { OrganizationSimpleResponse } from './OrganizationSimpleResponse';
import {
    OrganizationSimpleResponseFromJSON,
    OrganizationSimpleResponseFromJSONTyped,
    OrganizationSimpleResponseToJSON,
} from './OrganizationSimpleResponse';
import type { UserSimpleResponse } from './UserSimpleResponse';
import {
    UserSimpleResponseFromJSON,
    UserSimpleResponseFromJSONTyped,
    UserSimpleResponseToJSON,
} from './UserSimpleResponse';

/**
 * 
 * @export
 * @interface MediaSequenceResponse
 */
export interface MediaSequenceResponse {
    /**
     * 
     * @type {Date}
     * @memberof MediaSequenceResponse
     */
    lastModifiedUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof MediaSequenceResponse
     */
    createdUtc: Date;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceResponse
     */
    name: string;
    /**
     * 
     * @type {EConcatenationMode}
     * @memberof MediaSequenceResponse
     */
    concatenationMode?: EConcatenationMode;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceResponse
     */
    transcription?: string | null;
    /**
     * 
     * @type {ETranscriptionState}
     * @memberof MediaSequenceResponse
     */
    transcriptionState?: ETranscriptionState;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceResponse
     */
    caseId?: string | null;
    /**
     * 
     * @type {CaseResponse}
     * @memberof MediaSequenceResponse
     */
    _case?: CaseResponse;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceResponse
     */
    userId?: string | null;
    /**
     * 
     * @type {UserSimpleResponse}
     * @memberof MediaSequenceResponse
     */
    user?: UserSimpleResponse;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceResponse
     */
    metaMappingUserId?: string | null;
    /**
     * 
     * @type {UserSimpleResponse}
     * @memberof MediaSequenceResponse
     */
    metaMappingUser?: UserSimpleResponse;
    /**
     * 
     * @type {number}
     * @memberof MediaSequenceResponse
     */
    mutationsRegistered: number;
    /**
     * 
     * @type {number}
     * @memberof MediaSequenceResponse
     */
    mutationsCompleted: number;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceResponse
     */
    organizationId: string;
    /**
     * 
     * @type {OrganizationSimpleResponse}
     * @memberof MediaSequenceResponse
     */
    organization?: OrganizationSimpleResponse;
    /**
     * 
     * @type {EMediaSequenceState}
     * @memberof MediaSequenceResponse
     */
    state: EMediaSequenceState;
    /**
     * 
     * @type {EMediaSequenceType}
     * @memberof MediaSequenceResponse
     */
    type: EMediaSequenceType;
    /**
     * 
     * @type {boolean}
     * @memberof MediaSequenceResponse
     */
    showTranscriptionsStep: boolean;
    /**
     * 
     * @type {Array<MediaSequenceBranchResponse>}
     * @memberof MediaSequenceResponse
     */
    branches: Array<MediaSequenceBranchResponse>;
    /**
     * 
     * @type {Array<MediaSequenceBranchMutationResponse>}
     * @memberof MediaSequenceResponse
     */
    branchMutations: Array<MediaSequenceBranchMutationResponse>;
}

/**
 * Check if a given object implements the MediaSequenceResponse interface.
 */
export function instanceOfMediaSequenceResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModifiedUtc" in value;
    isInstance = isInstance && "createdUtc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "mutationsRegistered" in value;
    isInstance = isInstance && "mutationsCompleted" in value;
    isInstance = isInstance && "organizationId" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "showTranscriptionsStep" in value;
    isInstance = isInstance && "branches" in value;
    isInstance = isInstance && "branchMutations" in value;

    return isInstance;
}

export function MediaSequenceResponseFromJSON(json: any): MediaSequenceResponse {
    return MediaSequenceResponseFromJSONTyped(json, false);
}

export function MediaSequenceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaSequenceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModifiedUtc': (new Date(json['lastModifiedUtc'])),
        'createdUtc': (new Date(json['createdUtc'])),
        'id': json['id'],
        'name': json['name'],
        'concatenationMode': !exists(json, 'concatenationMode') ? undefined : EConcatenationModeFromJSON(json['concatenationMode']),
        'transcription': !exists(json, 'transcription') ? undefined : json['transcription'],
        'transcriptionState': !exists(json, 'transcriptionState') ? undefined : ETranscriptionStateFromJSON(json['transcriptionState']),
        'caseId': !exists(json, 'caseId') ? undefined : json['caseId'],
        '_case': !exists(json, 'case') ? undefined : CaseResponseFromJSON(json['case']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserSimpleResponseFromJSON(json['user']),
        'metaMappingUserId': !exists(json, 'metaMappingUserId') ? undefined : json['metaMappingUserId'],
        'metaMappingUser': !exists(json, 'metaMappingUser') ? undefined : UserSimpleResponseFromJSON(json['metaMappingUser']),
        'mutationsRegistered': json['mutationsRegistered'],
        'mutationsCompleted': json['mutationsCompleted'],
        'organizationId': json['organizationId'],
        'organization': !exists(json, 'organization') ? undefined : OrganizationSimpleResponseFromJSON(json['organization']),
        'state': EMediaSequenceStateFromJSON(json['state']),
        'type': EMediaSequenceTypeFromJSON(json['type']),
        'showTranscriptionsStep': json['showTranscriptionsStep'],
        'branches': ((json['branches'] as Array<any>).map(MediaSequenceBranchResponseFromJSON)),
        'branchMutations': ((json['branchMutations'] as Array<any>).map(MediaSequenceBranchMutationResponseFromJSON)),
    };
}

export function MediaSequenceResponseToJSON(value?: MediaSequenceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModifiedUtc': (value.lastModifiedUtc.toISOString()),
        'createdUtc': (value.createdUtc.toISOString()),
        'id': value.id,
        'name': value.name,
        'concatenationMode': EConcatenationModeToJSON(value.concatenationMode),
        'transcription': value.transcription,
        'transcriptionState': ETranscriptionStateToJSON(value.transcriptionState),
        'caseId': value.caseId,
        'case': CaseResponseToJSON(value._case),
        'userId': value.userId,
        'user': UserSimpleResponseToJSON(value.user),
        'metaMappingUserId': value.metaMappingUserId,
        'metaMappingUser': UserSimpleResponseToJSON(value.metaMappingUser),
        'mutationsRegistered': value.mutationsRegistered,
        'mutationsCompleted': value.mutationsCompleted,
        'organizationId': value.organizationId,
        'organization': OrganizationSimpleResponseToJSON(value.organization),
        'state': EMediaSequenceStateToJSON(value.state),
        'type': EMediaSequenceTypeToJSON(value.type),
        'showTranscriptionsStep': value.showTranscriptionsStep,
        'branches': ((value.branches as Array<any>).map(MediaSequenceBranchResponseToJSON)),
        'branchMutations': ((value.branchMutations as Array<any>).map(MediaSequenceBranchMutationResponseToJSON)),
    };
}

