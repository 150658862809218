import { useGetTicket } from 'api/useServiceTicketApi';
import { Page } from 'layouts/Admin/Page';
import { useParams } from 'react-router-dom';
import { useAuth } from 'auth/AuthProvider';
import {
  EAssetType,
  EPermission,
  EServiceTicketState,
  ETranscriptionState,
} from 'api/core';
import { twMerge } from 'tailwind-merge';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { ServiceTicketReplyForm } from './ServiceTicketReplyForm';
import { formatDistanceToNow, formatTimestamp } from 'utils/format/date';
import { Tooltip } from 'components/Tooltip';
import { useMemo } from 'react';
import { AssetPreviewModal } from 'components/Asset/AssetPreviewModal';
import {
  mediaSequenceStateTranslate,
  publicationStateTranslate,
  serviceTicketStateTranslate,
  transcriptionStateTranslate,
} from 'utils/enum-translate';
import { Link } from 'components/Typography/Link';

export const ServiceTicket = () => {
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();

  const { data } = useGetTicket(id);

  const viewingFromOherOrg = user?.organization.id != data?.organization.id;

  const { hasPermissions } = useAuth();
  const userIsSupport = hasPermissions([EPermission.ServiceTicketSupporter]);

  const alertMessage = useMemo(() => {
    if (!data) return;
    switch (data.state) {
      case EServiceTicketState.AwaitingCustomer:
        return userIsSupport
          ? 'Denne sag afventer en opdatering fra kunden.'
          : 'Denne sag afventer en opdatering fra dig.';
      case EServiceTicketState.AwaitingRelease:
      case EServiceTicketState.AwaitingSupport:
        return userIsSupport
          ? 'Denne Denne sag afventer en opdatering fra dig.'
          : 'Denne sag afventer en opdatering fra support.';
      case EServiceTicketState.Done:
        return 'Denne sag er lukket og kan ikke længere besvares.';
      default:
        break;
    }
  }, [data, userIsSupport]);

  if (!data) return null;

  return (
    <Page
      title={data.title}
      breadcrumbs={[
        { name: user?.organization.name ?? '' },
        { name: 'Support' },
        { name: data.title },
      ]}
      alert={
        alertMessage
          ? {
              message: alertMessage,
              type: 'info',
            }
          : undefined
      }
    >
      <div className="flex flex-col-reverse lg:flex-row gap-4">
        <div className="w-full lg:w-2/3 flex flex-col gap-2 bg-primary bg-opacity-5 rounded-md p-4">
          <div className="h-96 px-6 overflow-y-auto overflow-x-hidden">
            {data.replies.map((reply) => {
              const isLeftSide = reply.isFromSupport !== userIsSupport;
              return (
                <div
                  key={reply.id}
                  className={twMerge(
                    'chat flex flex-col gap-1',
                    isLeftSide && 'chat-start',
                    !isLeftSide && 'chat-end'
                  )}
                >
                  <div className="chat-header flex flex-col">
                    {reply.isInternal ? (
                      <span className="text-xs text-gray-600">
                        (Intern besked)
                      </span>
                    ) : null}
                    {reply.user.name}:
                  </div>
                  <div
                    className={twMerge(
                      'chat-bubble',
                      reply.isInternal && 'chat-bubble-warning text-black',
                      !reply.isInternal &&
                        isLeftSide &&
                        'chat-bubble-secondary text-black',
                      !reply.isInternal &&
                        !isLeftSide &&
                        'chat-bubble-primary text-white'
                    )}
                  >
                    {reply.message}
                    {reply.assets.length > 0 && (
                      <div className="flex flex-wrap gap-2 mt-2">
                        {reply.assets.map((asset) => (
                          <div
                            key={asset.id}
                            className="bg-gray-100 border border-gray-300 p-2 rounded-lg flex flex-col items-center"
                          >
                            <div className="text-xs text-gray-700 mb-1 truncate max-w-[20ch]">
                              {asset.originalFileName}
                            </div>
                            <div
                              className={twMerge(
                                asset.type === EAssetType.Audio && 'w-40'
                              )}
                            >
                              <AssetPreviewModal
                                asset={asset}
                                className="h-8"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="chat-footer text-xs text-gray-500 flex flex-col gap-1">
                    <div className="flex items-center gap-1 mt-1">
                      <Tooltip
                        tooltip={formatTimestamp(new Date(reply.createdUtc))}
                      >
                        <div>
                          {formatDistanceToNow(new Date(reply.createdUtc))}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="divider"></div>
          <PermissionProtectedComponent
            permissions={[EPermission.ServiceTicketWrite]}
          >
            <ServiceTicketReplyForm ticket={data} />
          </PermissionProtectedComponent>
        </div>

        <div className="flex flex-col gap-4 w-full lg:w-1/3 lg:self-start">
          <div className="card border w-full shadow-sm">
            <div className="card-body p-4">
              <h2 className="card-title">Information</h2>
              <div className="overflow-x-auto">
                <table className="table table-zebra">
                  <tbody>
                    <tr>
                      <td>Organisation tlf.:</td>
                      <td>{data.organization.phone}</td>
                    </tr>
                    <tr>
                      <td>Oprettet af:</td>
                      <td>{data.createdByUser.name}</td>
                    </tr>
                    <tr>
                      <td>Status:</td>
                      <td>{serviceTicketStateTranslate(data.state)}</td>
                    </tr>
                    <tr>
                      <td>Seneste opdatering:</td>
                      <td>
                        {formatDistanceToNow(new Date(data.lastModifiedUtc))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {viewingFromOherOrg ? (
            <div className="card border bg-red-300 w-full shadow-sm">
              <div className="card-body p-4">
                <h2 className="card-title">Bemærk</h2>
                <div className="card-text">
                  Denne sag er oprettet af en anden organisation. Du kan se den
                  fordi du er support.
                  <br />
                  Upload af filer er deaktiveret, da de ellers ville ende hos{' '}
                  {user?.organization.name}.
                </div>
              </div>
            </div>
          ) : null}

          {data._case ? (
            <div className="card border w-full shadow-sm">
              <div className="card-body p-4">
                <h2 className="card-title">Sag</h2>
                <div className="overflow-x-auto">
                  <table className="table table-zebra">
                    <tbody>
                      <tr>
                        <td>Adresse:</td>
                        <td>
                          <Link to={`/cases/${data._case.id}`}>
                            {data._case.address}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>Projekt</td>
                        <td>
                          <Link to={`/projects/${data._case.project.id}`}>
                            {data._case.project.name}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>Status:</td>
                        <td>{data._case.state}</td>
                      </tr>
                      <tr>
                        <td>Seneste opdatering:</td>
                        <td>
                          {formatDistanceToNow(
                            new Date(data._case.lastModifiedUtc)
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}

          {data.project ? (
            <div className="card border w-full shadow-sm">
              <div className="card-body p-4">
                <h2 className="card-title">Projekt</h2>
                <div className="overflow-x-auto">
                  <table className="table table-zebra">
                    <tbody>
                      <tr>
                        <td>
                          {data.project.singleCase ? 'Adresse' : 'Projektnavn'}:
                        </td>
                        <td>
                          <Link
                            to={
                              data.project.singleCase
                                ? `/projects/${data.project.id}/case`
                                : `/projects/${data.project.id}/cases`
                            }
                          >
                            {data.project.name}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>Seneste opdatering:</td>
                        <td>
                          {formatDistanceToNow(
                            new Date(data.project.lastModifiedUtc)
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}

          {data.publication ? (
            <div className="card border w-full shadow-sm">
              <div className="card-body p-4">
                <h2 className="card-title">Smart Planner</h2>
                <div className="overflow-x-auto">
                  <table className="table table-zebra">
                    <tbody>
                      <tr>
                        <td>Navn:</td>
                        <td>
                          <Link to={`/publications/${data.publication.id}`}>
                            {data.publication.name}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>Status:</td>
                        <td>
                          {publicationStateTranslate(data.publication.state)}
                        </td>
                      </tr>
                      <tr>
                        <td>Seneste opdatering:</td>
                        <td>
                          {formatDistanceToNow(
                            new Date(data.publication.lastModifiedUtc)
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}

          {data.mediaSequence ? (
            <div className="card border w-full shadow-sm">
              <div className="card-body p-4">
                <h2 className="card-title">Smart Video</h2>
                <div className="overflow-x-auto">
                  <table className="table table-zebra">
                    <tbody>
                      <tr>
                        <td>Adresse:</td>
                        <td>
                          <Link
                            to={`/media-sequences/${data.mediaSequence.id}`}
                          >
                            {data.mediaSequence.name}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>Status:</td>
                        <td>
                          {mediaSequenceStateTranslate(
                            data.mediaSequence.state
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Undertekst status:</td>
                        <td>
                          {transcriptionStateTranslate(
                            data.mediaSequence.transcriptionState ||
                              ETranscriptionState.NotApplicable
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Effekter fuldført:</td>
                        <td>
                          {data.mediaSequence.mutationsCompleted} ud af{' '}
                          {data.mediaSequence.mutationsRegistered}
                        </td>
                      </tr>
                      <tr>
                        <td>Seneste opdatering:</td>
                        <td>
                          {formatDistanceToNow(
                            new Date(data.mediaSequence.lastModifiedUtc)
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Page>
  );
};
