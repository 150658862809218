import { AssetMetadataWithValue, EAssetMetadata, EAssetSource } from 'api/core';
import {
  useBeginCreateAsset,
  useFinalizeCreateAsset,
  useUploadPresignedFileUrl,
} from 'api/useAssetsApi';
import { ControlledModal } from 'components/ControlledModal';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { ReactNode, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { calculateChecksumAsync, getVideoDurationAsync } from 'utils/file-util';
import { AutocompleteAddress } from './AutocompleteAddress';
import { useForm } from 'react-hook-form';

interface AssetDragAndDropUploadContainerProps {
  projectId?: string;
  children: ReactNode;
}

export const AssetDragAndDropUploadContainer = ({
  projectId,
  children,
}: AssetDragAndDropUploadContainerProps) => {
  const { mutateAsync: createAsync } = useBeginCreateAsset();
  const { mutateAsync: uploadFileAsync } = useUploadPresignedFileUrl();
  const { mutateAsync: markAsync } = useFinalizeCreateAsset();

  const [isUploading, setIsUploading] = useState(false);
  const [filesCount, setFilesCount] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState(0);
  const [uploadFn, setUploadFn] =
    useState<(address: string | null) => Promise<void>>();
  const [showRenameDialog, setShowRenameDialog] = useState(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop: async (files: File[]) => {
      setFilesCount(files.length);
      if (files.length < 1) return;

      const startUploadingAsync = async (address: string | null) => {
        setFilesUploaded(1);
        for (let i = 0; i < files.length; i++) {
          if (address) {
            const fileExtension = files[i].name.split('.').pop();
            files[i] = new File(
              [files[i]],
              `${address}, fil ${i + 1}.${fileExtension}`,
              {
                type: files[i].type,
              }
            );
          }
          await handleFileUpload(files[i]);
          setFilesUploaded((prev) => prev + 1);
        }
      };

      // If only one file, start uploading immediately
      if (files.length == 1) {
        await startUploadingAsync(null);
        return;
      }

      // Otherwise show dialog to enter address, and start uploading when address is confirmed
      setUploadFn(
        () => (address: string | null) => startUploadingAsync(address)
      );
      setShowRenameDialog(true);
    },
  });

  const handleFileUpload = async (file: File) => {
    setIsUploading(true);

    const metadata: AssetMetadataWithValue[] = [];

    try {
      if (file.type.startsWith('video/')) {
        const duration = await getVideoDurationAsync(file);
        metadata.push({
          key: EAssetMetadata.VideoDuration,
          value: duration.toString(),
        });
      }
    } catch (error) {
      console.warn('Error getting video duration', error);
    }

    const sha256 = await calculateChecksumAsync(file);
    const assetResponse = await createAsync({
      assetCreateRequest: {
        projectId,
        fileName: file.name,
        mimeType: file.type,
        fileSize: file.size,
        checksumSha256: sha256,
        source: EAssetSource.UserUploaded,
        metadata: metadata,
      },
    });

    if (assetResponse.foundMatchingAsset) {
      setIsUploading(false);
      return;
    }

    await uploadFileAsync({
      url: assetResponse.presignedUploadUrl,
      file,
    });

    await markAsync({ id: assetResponse.id });
    setIsUploading(false);
  };

  return (
    <div {...getRootProps()} className="h-full">
      <input {...getInputProps()} className="hidden" />
      {isDragActive ? (
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-50 text-white flex items-center justify-center text-lg z-50">
          <div className="flex flex-col items-center">
            <AnimatedIcon
              icon="folder-icon"
              autoPlay
              loop
              className="h-64 w-64"
            />
            <h2 className="text-2xl">Slip filerne her for at uploade dem</h2>
          </div>
        </div>
      ) : null}
      {isUploading ? (
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-50 text-white flex items-center justify-center text-lg z-50">
          <div className="flex flex-col items-center">
            <AnimatedIcon
              icon="upload-icon"
              autoPlay
              loop
              className="h-64 w-64"
            />
            <h2 className="text-2xl">
              Uploader fil {filesUploaded} ud af {filesCount}...
            </h2>
          </div>
        </div>
      ) : null}

      <EnterAddressDialog
        isOpen={showRenameDialog}
        setIsOpen={setShowRenameDialog}
        fileCount={filesCount}
        onAddressConfirmed={(address) => {
          setShowRenameDialog(false);
          uploadFn?.(address);
        }}
        onCancel={() => {
          setShowRenameDialog(false);
          uploadFn?.(null);
          setUploadFn(undefined);
        }}
      />
      {children}
    </div>
  );
};

interface EnterAddressDialogProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  onCancel: () => void;
  onAddressConfirmed: (address: string) => void;
  fileCount: number;
}

export const EnterAddressDialog = ({
  isOpen,
  setIsOpen,
  onCancel,
  onAddressConfirmed,
  fileCount,
}: EnterAddressDialogProps) => {
  const { register, handleSubmit, watch, setValue } = useForm<{
    address: string;
  }>({
    defaultValues: {
      address: '',
    },
  });

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onCancel?.();
    }
  };

  const onSubmit = handleSubmit(() => {
    const address = watch('address')?.trim();

    if (address) {
      onAddressConfirmed(address);
    } else {
      onCancel();
    }
  });

  return (
    <ControlledModal
      showModal={onModalStateChange}
      isOpen={isOpen}
      title="Omdøb filer"
      closeOnOutsideClick={false}
      hideActionBar
    >
      <form onSubmit={onSubmit} className="space-y-2">
        <p>
          Du er ved at uploade {fileCount} fil{fileCount > 1 ? 'er' : ''}.
          Ønsker du at omdøbe filerne med en adresse? Hvis ja, indtast adressen
          herunder. Hvis nej, tryk på &quot;Fortsæt uden at omdøbe&quot;.
        </p>
        <AutocompleteAddress
          label={
            <p>
              Adresse <span className="text-red-500">*</span>
            </p>
          }
          registration={() => register('address', { required: true })}
          formWatch={watch('address') || ''}
          formSetValue={(value: string) =>
            setValue('address', value, { shouldValidate: true })
          }
          inputClassName="input input-bordered"
        />

        <div className="flex justify-center space-x-4 pt-4">
          <button type="submit" className="btn btn-primary">
            Brug adresse
          </button>

          <button type="button" className="btn" onClick={onCancel}>
            Fortsæt uden at omdøbe
          </button>
        </div>
      </form>
    </ControlledModal>
  );
};
