import { EPermission, EStatTypeAction, StatResponse } from 'api/core';
import { useGetStats } from 'api/useStatsApi';
import { useAuth } from 'auth/AuthProvider';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { AnimatedIcon, AnimatedIconKey } from 'components/Icon/AnimatedIcon';
import { Page } from 'layouts/Admin/Page';
import { MediaSequenceCreate } from 'pages/MediaOrbit/components/MediaSequence/MediaSequenceCreate';
import { SmartTextCreate } from 'pages/SmartText/SmartTextCreate';
import { ReactNode } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export const DashboardView = () => {
  const { data } = useGetStats();
  const { user } = useAuth();

  if (!data) return null;

  return (
    <Page title={user?.organization.name || 'Oversigt'}>
      <WelcomeText />
      <StatGrid stats={data} />
    </Page>
  );
};

const WelcomeText = () => {
  return (
    <div className="mb-4">
      <h1 className="font-bold">Velkommen til Smart Create</h1>
      <div className="text-gray-600">
        <p>
          Hvis du har spørgsmål, feedback eller skulle opleve udfordringer, er
          du velkommen til at oprette en{' '}
          <Link className="text-primary" to="/organization/service-tickets">
            supportsag
          </Link>
          , så hjælper vi dig videre.
        </p>
      </div>
    </div>
  );
};

const StatGrid = ({ stats }: { stats: StatResponse[] }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-4 4xl:grid-cols-6 gap-6">
      {stats.map((stat, i) => (
        <StatCard
          key={i}
          title={stat.title}
          description={stat.description}
          value={stat.value}
          link={stat.link}
          iconKey={stat.iconKey as AnimatedIconKey}
          actionRequired={stat.actionRequired}
          actionType={stat.actionType}
          actionPermissions={stat.actionPermissions ?? []}
        />
      ))}
    </div>
  );
};

interface GridItemProps {
  children: React.ReactNode;
  colSpan?: number;
  mobileOnly?: boolean;
  actionRequired?: boolean;
  permissions?: EPermission[];
}

const GridItem = ({
  children,
  colSpan,
  mobileOnly,
  actionRequired,
  permissions,
}: GridItemProps) => {
  const { hasPermissions } = useAuth();
  if (permissions && !hasPermissions(permissions)) return null;

  return (
    <div
      className={twMerge(
        'stat border shadow-md border-gray-300 rounded-xl',
        !colSpan && 'col-span-1',
        mobileOnly && 'md:hidden',
        colSpan && `col-span-${colSpan}`,
        actionRequired && 'border-2 border-warning'
      )}
    >
      {children}
    </div>
  );
};

interface StatCardProps {
  title: string;
  description: string;
  value: number;
  iconKey: AnimatedIconKey;
  link: string;
  actionRequired?: boolean;
  actionType?: EStatTypeAction;
  actionPermissions: EPermission[];
}

const StatCard = ({
  title,
  description,
  value,
  iconKey,
  link,
  actionRequired,
  actionType,
  actionPermissions,
}: StatCardProps) => {
  return (
    <>
      <NavLink to={link} className="py-1">
        <GridItem actionRequired={actionRequired}>
          <div className="stat-figure text-primary flex justify-center">
            <AnimatedIcon icon={iconKey} className="w-10 h-10" />
          </div>
          <div className="stat-title mt-1 text-center">{title}</div>
          <div className="stat-value text-primary text-center">{value}</div>
          <div className="stat-desc mb-1 text-center truncate">
            {description}
          </div>
        </GridItem>
      </NavLink>
      {actionType ? (
        <div className="block md:hidden">
          <PermissionProtectedComponent permissions={actionPermissions}>
            {actionType === EStatTypeAction.CreateMediaSequence ? (
              <MediaSequenceCreate
                trigger={<ActionButton>Opret Smart Video</ActionButton>}
              />
            ) : null}
            {actionType === EStatTypeAction.CreateText ? (
              <SmartTextCreate
                trigger={<ActionButton>Opret Smart Text</ActionButton>}
              />
            ) : null}
          </PermissionProtectedComponent>
        </div>
      ) : null}
    </>
  );
};

const ActionButton = ({ children }: { children: ReactNode }) => {
  return <button className="btn btn-primary w-full">{children}</button>;
};
