export const calculateChecksumAsync = async (file: File) => {
  const buffer = await file.arrayBuffer();
  const digest = await crypto.subtle.digest('SHA-256', buffer);
  return hexString(digest);
};

export const getVideoDurationAsync = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    if (!file.type.startsWith('video/')) {
      reject('Invalid file type');
      return;
    }

    const video = document.createElement('video');
    const url = URL.createObjectURL(file);

    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      URL.revokeObjectURL(url);
      resolve(video.duration);
      video.remove();
    };

    video.onerror = (error) => {
      URL.revokeObjectURL(url);
      video.remove();
      reject('Error loading video metadata: ' + error);
    };

    video.src = url;
  });
};

const hexString = (buffer: ArrayBuffer) => {
  const byteArray = new Uint8Array(buffer);
  const hexCodes = [...byteArray].map((value) => {
    return value.toString(16).padStart(2, '0');
  });
  return hexCodes.join('');
};
