import { AssetResponse, EAssetSource } from 'api/core';
import {
  useBeginCreateAsset,
  useFinalizeCreateAsset,
  useUploadPresignedFileUrl,
} from 'api/useAssetsApi';
import { FullscreenLoadingWithText } from 'components/Loading';
import { useState } from 'react';
import { calculateChecksumAsync } from 'utils/file-util';

interface AssetUploadProps {
  fileInputRef: React.RefObject<HTMLInputElement>;
  projectId?: string;
  onAssetUploaded?: (asset: AssetResponse) => void;
  accept?: string;
  allowMultiple?: boolean;
  source?: EAssetSource;
}

export const AssetUpload = ({
  fileInputRef,
  projectId,
  onAssetUploaded,
  accept,
  allowMultiple,
  source,
}: AssetUploadProps) => {
  const { mutateAsync: createAsyncAsync } = useBeginCreateAsset();
  const { mutateAsync: uploadFileAsync } = useUploadPresignedFileUrl();
  const { mutateAsync: markAsync } = useFinalizeCreateAsset();
  const [isUploading, setIsUploading] = useState(false);

  const [filesCount, setFilesCount] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState(0);

  const handleFileUpload = async (file: File) => {
    const sha256 = await calculateChecksumAsync(file);
    const assetResponse = await createAsyncAsync({
      assetCreateRequest: {
        projectId,
        fileName: file.name,
        mimeType: file.type,
        fileSize: file.size,
        checksumSha256: sha256,
        source: source || EAssetSource.UserUploaded,
        metadata: [],
      },
    });

    if (assetResponse.foundMatchingAsset) {
      if (onAssetUploaded) {
        // TODO: Get asset by id, but we are not allowed to that in this function or something
        // const assetGetResponse = await getAssetAsync({ id: assetResponse.id });
        // onAssetUploaded(assetGetResponse);
        // Since we can't do that, just hack it for now...
        onAssetUploaded({
          id: assetResponse.id,
          projectId: assetResponse.projectId,
          lastModifiedUtc: assetResponse.lastModifiedUtc,
          createdUtc: assetResponse.createdUtc,
          type: assetResponse.type,
          fileSize: assetResponse.fileSize,
          isGlobal: false,
          originalFileName: assetResponse.originalFileName,
          tags: [],
          url: assetResponse?.url || '',
          previewUrl: assetResponse.previewUrl,
          userId: assetResponse.userId,
          source: assetResponse.source,
        });
      }
      return;
    }

    await uploadFileAsync({
      url: assetResponse.presignedUploadUrl,
      file,
    });

    const result = await markAsync({ id: assetResponse.id });
    onAssetUploaded?.(result);
  };

  const handleFiles = async (files: FileList) => {
    setFilesUploaded(1);
    setFilesCount(files.length);
    setIsUploading(true);
    for (let i = 0; i < files.length; i++) {
      await handleFileUpload(files[i]);
      setFilesUploaded(i + 2);
    }
    setIsUploading(false);
  };

  return (
    <>
      <input
        type="file"
        multiple={allowMultiple}
        ref={fileInputRef}
        accept={accept}
        onChange={async (event) => {
          const files = event.target.files;
          if (!files) return;
          await handleFiles(files);
        }}
        className="hidden"
      />
      {isUploading ? (
        <FullscreenLoadingWithText
          text={`Uploader fil ${filesUploaded} ud af ${filesCount}...`}
          icon="upload-icon"
        />
      ) : null}
    </>
  );
};
