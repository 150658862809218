import {
  AssetSimpleResponse,
  EAssetType,
  MediaSequenceAssetMutationCreateRequest,
  MediaSequenceAssetMutationResponse,
  MediaSequenceAssetMutationUpdateRequest,
} from 'api/core';
import { AudioPreview } from 'components/Audio/AudioPreview';
import { AssetUpload } from 'components/Form/AssetUpload';
import { AssetSelectSingleAsync } from 'components/Select/AssetSelectSingleAsync';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface SoundEffectProps {
  targetAssetMutation?: MediaSequenceAssetMutationResponse;
  projectId?: string;
  inDialog?: boolean;
}

export const SoundEffect = ({
  targetAssetMutation,
  projectId,
  inDialog,
}: SoundEffectProps) => {
  const {
    register,
    setValue,
    formState: { disabled },
  } = useFormContext<
    | MediaSequenceAssetMutationCreateRequest
    | MediaSequenceAssetMutationUpdateRequest
  >();

  const soundEffectInputRef = useRef<HTMLInputElement>(null);

  const [initialSelectedSoundEffectAsset, setInitialSelectedSoundEffectAsset] =
    useState<AssetSimpleResponse | undefined>(
      targetAssetMutation?.soundEffect?.asset
    );

  const onSoundEffectSelected = (asset: AssetSimpleResponse | null) => {
    if (!asset) return;
    setValue('soundEffect.assetId', asset.id, { shouldValidate: true });
    setInitialSelectedSoundEffectAsset(asset);
  };

  return (
    <>
      <AssetUpload
        fileInputRef={soundEffectInputRef}
        projectId={projectId}
        onAssetUploaded={onSoundEffectSelected}
        accept="audio/*"
      />
      <div className="form-control">
        <label className="label">
          <span className="label-text">Lyd</span>
        </label>
        <AssetSelectSingleAsync
          // Allow the user to seearch in all assets on organization (Not just project assets)
          // projectId={projectId}
          types={[EAssetType.Audio]}
          onSelected={onSoundEffectSelected}
          initialValue={initialSelectedSoundEffectAsset}
          showCreateOption
          onCreateOptionSelected={() => soundEffectInputRef.current?.click()}
          inDialog={inDialog}
          disabled={disabled}
        />
        <input
          {...register('soundEffect.assetId', { required: true })}
          type="text"
          className="hidden"
        />
        {initialSelectedSoundEffectAsset ? (
          <AudioPreview
            className="mt-4"
            asset={initialSelectedSoundEffectAsset}
            volume={1}
          />
        ) : null}
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Forsinkelse</span>
        </label>
        <input
          {...register('soundEffect.delayInSeconds', { required: true })}
          type="number"
          min={0}
          max={60}
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Lydniveau</span>
        </label>
        <input
          {...register('soundEffect.volume', { required: true })}
          type="number"
          min={0.1}
          max={1}
          step={0.1}
          className="input input-bordered"
        />
      </div>
    </>
  );
};
